<template>
    <div class="d-flex flex-wrap flex-stack my-5">
        <router-link v-if="!$root.filterWithUrl" to="/module/question-solution/category" class="btn btn-primary align-self-center">
            <span class="svg-icon svg-icon-2">
                <inline-svg src="/media/icons/duotune/arrows/arr021.svg" />
            </span>
            {{ $t("pages.module.questionSolution.category.title") }}
        </router-link>
    </div>
    <custom-table
        :title="sprintf($t('pages.module.questionSolution.book.titlePattern'), [category.title ?? ''])"
        :subTitle="$t('pages.module.questionSolution.book.subTitle')"
        selectableRows
        rowKey="id"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        filterComponentName="ModuleQuestionSolutionBookFilter"
        @action="handleClickAction"
        @selectableRows="handleSelectedRow"
        @changeTable="handleTableChange">
        <template v-slot:status="{ row: record }">
            <span :class="record.active ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.active ? $t('common.active') : $t('common.passive') }}</span>
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <el-tooltip :content="$t('pages.module.questionSolution.book.chapter.title')" placement="top">
                    <router-link :to="sprintf('/module/question-solution/category/%d/book/%d/chapter', [categoryID, record.id])" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                        <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/text/txt009.svg" />
                        </span>
                    </router-link>
                </el-tooltip>
                <a v-on:click="fetchRecord(record)" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/art/art005.svg" />
                    </span>
                </a>
                <el-popconfirm :title="$t('messages.sureDelete')" :confirm-button-text="$t('btn.yes')" :cancel-button-text="$t('btn.no')" @confirm="deleteRecord([record.id])">
                    <template #reference>
                        <a class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                            <span class="svg-icon svg-icon-3">
                                <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                            </span>
                        </a>
                    </template>
                </el-popconfirm>
            </div>
        </template>
    </custom-table>
    <div class="modal fade" id="kt_modal_book" ref="bookModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="fw-bolder">{{ form.title }}</h2>
                    <div id="kt_modal_add_customer_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
                        </span>
                    </div>
                </div>
                <el-form @submit.prevent="onSubmit()" :model="form.data" ref="bookForm">
                    <div class="modal-body py-10 px-lg-17">
                        <div class="scroll-y me-n7 pe-7" id="kt_modal_add_customer_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                            <div class="fv-row mb-7">
                                <label class="required fs-6 fw-bold mb-2">{{ $t('pages.module.questionSolution.book.cols.title') }}</label>
                                <el-form-item prop="title" :rules="$validation.getMessage(['required'])">
                                    <el-input v-model="form.data.title" type="text"/>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('pages.module.questionSolution.book.cols.image') }}</label>
                                <el-form-item prop="image_id">
                                    <CustomImage :handleImage="form.data.image" @updateImage="image = $event"></CustomImage>
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-7">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.sort') }}</label>
                                <el-form-item prop="sort" :rules="$validation.getMessage(['required'])">
                                    <el-input-number v-model="form.data.sort" :min="1" />
                                </el-form-item>
                            </div>

                            <div class="fv-row mb-10">
                                <label class="fs-6 fw-bold mb-2">{{ $t('common.status') }}</label>
                                <el-form-item prop="active">
                                    <el-radio-group v-model="form.data.active">
                                        <el-radio-button :label="true">{{ $t("common.active") }}</el-radio-button>
                                        <el-radio-button :label="false">{{ $t("common.passive") }}</el-radio-button>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer flex-center">
                        <button :data-kt-indicator="form.loading ? 'on' : null" class="btn btn-lg btn-primary" type="submit" :disabled="form.loading">
                            <span v-if="!form.loading" class="indicator-label">{{ $t("btn.save") }}</span>
                            <span v-if="form.loading" class="indicator-progress">
                                {{ $t("messages.wait") }}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";
import CustomImage from "@/components/custom-image";

export default {
    name: "index",
    components: {
        CustomTable,
        CustomImage
    },
    data(){
        return {
            fields: [
                {
                    name: this.$t("common.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.module.questionSolution.book.cols.title"),
                    key: "title",
                },
                {
                    name: this.$t("pages.module.questionSolution.book.cols.chapterCount"),
                    key: "chapters_count",
                    class: ""
                },
                {
                    name: this.$t("common.sort"),
                    key: "sort",
                    class: ""
                },
                {
                    name: this.$t("common.status"),
                    scopedSlots: {customRender: "status"}
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "new",
                    icon: "bi-plus-lg",
                    label: "btn.add"
                },
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                },
                {
                    name: "delete",
                    icon: "bi-trash",
                    label: "btn.delete"
                }
            ],
            form: {
                title: '',
                loading: false,
                updateStatus: false,
                data: {}
            },
            category: {},
            selectedRowKeys: [],
            image: {
                uploadList: [],
                fileList:[]
            },
        }
    },
    computed: {
        categoryID() {
            return this.$route.params.id;
        },
        table() {
            return this.$store.state.module.questionSolution.book.table;
        },
    },
    created() {
        if (!this.categoryID || !(this.categoryID > 0)) {
            this.$router.push({
                path: "/module/question-solution/category"
            });
        }
    },
    mounted(){
        this.setCurrentPageBreadcrumbs(this.$t("pages.module.questionSolution.book.title"), [this.$t("menu.moduleManagement")]);

        if (this.categoryID && this.categoryID > 0) {
            this.$store.dispatch('module/questionSolution/book/get', {
                page: {},
                filterData: Object.assign(this.$root.getFilterWithUrl({}), {
                    category_id: this.categoryID
                })
            });
            this.loadCategory();
        }
    },
    methods: {
        loadCategory(){
            this.axios.get(this.endpoints['module_question_solution_category'] + '/' + this.categoryID).then((response) => {
                let data = response.data.data;
                this.category = data;
            }).catch((error) => {
                if(error.response.data.message == 'record_not_found') {
                    this.$router.push({
                        path: "/module/question-solution/category"
                    });
                }
            });
        },
        newRecord(){
            this.form.updateStatus = false;

            this.form.data = {
                category_id: this.categoryID,
                sort: 1,
                active: true
            };

            this.resetImageData();

            this.form.title = this.$t("pages.module.questionSolution.book.new");

            this.showModal(this.$refs.bookModal);
        },
        fetchRecord(record){
            this.form.updateStatus = true;
            this.axios.get(this.endpoints['module_question_solution_book'] + '/' + record.id).then(response => {
                let data = response.data.data;

                this.form.title = this.$t("pages.module.questionSolution.book.edit");
                this.form.data = data;

                if (data.image) {
                    data.image_id = data.image.id;

                    this.image.fileList = [
                        {
                            name: data.image.name,
                            url: data.image.public_url
                        }
                    ]
                }

                this.showModal(this.$refs.bookModal);
            });
        },
        onSubmit() {
            this.$refs.bookForm.validate((valid) => {
                if (valid) {
                    this.form.loading = true;

                    if (this.image.uploadList.length > 0) {
                        this.submitFormWithUpload();
                    } else {
                        if (!(this.image.fileList.length > 0)) {
                            this.form.data.image_id = null;
                        }
                        this.submitForm();
                    }
                } else {
                    return false;
                }
            });
        },
        submitFormWithUpload() {
            const formData = new FormData();

            this.image.uploadList.forEach(file => {
                formData.append("file[]", file.raw);
            });

            this.axios.post(this.endpoints['file'], formData).then(response => {
                this.onResponse(response.data, () => {
                    let data = response.data.data;
                    this.form.data.image_id = data[0].id;
                    this.image.uploadList = [];
                    this.submitForm();
                }, undefined, false);
            }).catch(error => {
                this.onResponseFailure(error.response.data);
            }).finally(() => {
                this.form.loading = false;
            });
        },
        submitForm() {
            this.form.loading = true;

            if(this.form.data.id) {
                this.axios.put(this.endpoints['module_question_solution_book'] + '/' + this.form.data.id, this.form.data).then(response => {
                    this.onResponse(response.data, () => {
                        this.refreshTable();
                        this.hideModal(this.$refs.bookModal);
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data);
                }).finally( () => {
                    this.form.loading = false;
                });
            }else {
                this.axios.post(this.endpoints['module_question_solution_book'], this.form.data).then(response => {
                    this.onResponse(response.data, () => {
                        this.refreshTable();
                        this.hideModal(this.$refs.bookModal);
                    });
                }).catch(error => {
                    this.onResponseFailure(error.response.data)
                }).finally( () => {
                    this.form.loading = false;
                });
            }
        },
        deleteRecord(id){
            this.$store.dispatch("module/questionSolution/book/delete", {
                id: id,
                refreshData: false
            }).then((successDeleted) => {
                this.selectedRowKeys = this.selectedRowKeys.filter(x => !successDeleted.includes(x));
            });
        },
        refreshTable(){
            this.$store.dispatch("module/questionSolution/book/refresh");
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                case "new":
                    this.newRecord();
                    break;

                case "delete":
                    this.deleteRecord(this.selectedRowKeys.flat());
                    break;

                default:
                    break;
            }
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("module/questionSolution/book/get", {
                page: pagination,
                filterData: Object.assign({
                    category_id: this.categoryID
                }, filterData)
            });
        },
        handleSelectedRow(record){
            this.selectedRowKeys = record;
        },
        resetImageData() {
            this.image = {uploadList: [], fileList: []};
        }
    }
}
</script>

<style>
</style>