<template>
    <div class="custom-image-container">
        <el-upload :accept="$root.acceptFileSupported('image', 'image/')" v-bind:class="{'disabled-upload': image.fileList.length > 0}" :on-change="handleChangeImage" list-type="picture-card" :file-list="image.fileList" limit="1" :on-preview="handlePictureCardPreview" :auto-upload="false">
            <i class="bi bi-plus"/>

            <template #file="{ file }" class="">
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt=""/>
                <label class="el-upload-list__item-status-label">
                    <i class="el-icon el-icon--upload-success el-icon--check">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                            <path fill="currentColor" d="M406.656 706.944 195.84 496.256a32 32 0 1 0-45.248 45.248l256 256 512-512a32 32 0 0 0-45.248-45.248L406.592 706.944z"></path>
                        </svg>
                    </i>
                </label>
                <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="bi bi-search"/>
                    </span>
                    <span class="el-upload-list__item-delete" @click="handleRemoveImage(file)">
                        <i class="bi bi-trash"/>
                    </span>
                </span>
            </template>
        </el-upload>
        <el-dialog v-model="image.dialogVisible" width="30%">
            <img style="width: 100%" :src="image.dialogImageUrl" alt=""/>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "index",
    props: {
        uploadList: {type: Array, default: () => { return [] }},
        editableImageFields: {type: Object, default: () => { return {} }},
        handleImage: {type: Object, default: undefined}, //Expect detail, data,
    },
    data() {
        return {
            image: {
                dialogImageUrl: '',
                dialogVisible: false,
                uploadList: [],
                fileList: []
            }
        }
    },
    created() {
        this.initHandleImage();
    },
    computed: {
        isAvailableHandleImage(){
            return this.handleImage && Object.keys(this.handleImage).length;
        }
    },
    methods: {
        initHandleImage(){
            this.image.fileList = [];

            if (this.isAvailableHandleImage) {
                let image = this.handleImage.image ? this.handleImage.image : this.handleImage;

                this.image.fileList = [
                    {
                        name: image.name,
                        url: image.public_url
                    }
                ];
            }
        },
        handlePictureCardPreview(file) {
            this.image.dialogImageUrl = file.url
            this.image.dialogVisible = true
        },
        handleChangeImage(file, fileList) {
            this.image.fileList = fileList;
            this.image.uploadList = fileList;

            this.updateImage();
        },
        updateImage(){
            let data = {
                fileList: this.image.fileList,
                uploadList: this.image.uploadList
            };

            this.$emit('updateImage', data)
        },
        handleRemoveImage(file) {
            let fileList = this.image.fileList.filter(fileItem => fileItem.name != file.name);
            this.handleChangeImage(file, fileList);
            if(this.isAvailableHandleImage){
                this.$emit('deleteImage', this.handleImage)
            }
        },
    },
    watch: {
        handleImage: function () {
            this.initHandleImage();
        },
        uploadList: function(newValue, oldValue) {
            this.image.uploadList = newValue;
        }
    }
}
</script>

<style>
.custom-image-container .el-upload-list{
    line-height: normal;
}

.custom-image-container .el-upload-list--picture-card .el-upload-list__item{
    margin:0;
}

.custom-image-container .el-upload-list__item-file-image, .custom-image-container .el-upload-list__item-edit, .custom-image-container .el-upload-list__item-delete {
    margin-left: 15px;
}

.poster-image {
    border: 1px solid #c0ccda;
    border-radius: 6px;
}

.poster-image img {
    object-fit: cover;
    width: 146px !important;
    height: 146px !important;
}

.poster-image .poster-image-overlay{
    border-radius: 5px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    text-align: center;
    color: #fff;
    opacity: 0;
    font-size: 20px;
    background-color: rgba(0,0,0,.5);
    transition: opacity 0.3s;
}

.poster-image .poster-image-overlay .poster-image-preview {
    margin-top: calc(50% - 21px);
    display: block;
}

.poster-image-show:hover .poster-image .poster-image-overlay{
    opacity: 1;
    cursor: pointer;
}

</style>